<template>
    <Loading v-show="isLoading" />
  
    <Panel v-show="!isLoading">
        <template #header>
            <span class="font-bold">DATOS DE ACCESO</span>
        </template>
        <div class="col-12 card mt-4">
          <form>
            <div class="formgrid grid">
              <div class="field col-6 sm:col-6 md:col-4 lg:col-4">
                <Dropdown 
                  v-model="form.reason" 
                  :options="reasons" 
                  optionLabel="name" 
                  optionValue="id" 
                  autofocus tabindex="1" 
                  class="inputfield w-full"
                  placeholder="Seleccionar Motivo" />
              </div>

              <div class="field col-12 md:col-12 lg:col-12">
                <Textarea 
                  v-model="form.description" 
                  placeholder="Descripción"
                  rows="5" 
                  class="inputfield w-full"/>
              </div>
              
              <div class="field col-12 text-center mt-4">
                <Button
                  class="p-button-primary"
                  icon="fas fa-save"
                  label="ENVIAR"
                  tabindex="5"
                  @click="handleAssistance()"
                />
              </div>
            </div>
          </form>
        </div>
    </Panel> 
</template>

<script>
  import router from '../../router'
  import apiService from '../../services/apiService.js'
  import Textarea from 'primevue/textarea';

  export default {
    components: (
      Textarea
    ),
    data(){
      return { 
        isLoading: false,
        reasons: [],
        form: {
          reason: '',
          description: '',
        }
      }
    },

    mounted(){
      this.optReasons();
    },

    methods: {
      handleAssistance(){
        this.isLoading = true
        apiService.Assistance.store(this.form)
        .then((result) => {
            if(result.status == 'success'){
                this.FormSuccess(result.message)
                this.resetForm()
                this.isLoading = false
                router.push("/support/" + result.assistance_id);
            }else{
                this.FormErrors(result)
                this.isLoading = false
            }
        }).catch((e) => {
            this.FormRequestFail(e);
            this.isLoading = false
        })
      },
      async optReasons(){
          this.isLoading = true
          apiService.Assistance.reasons()
          .then((result) => {
              this.reasons = result
              this.isLoading = false
          })
      },
      resetForm(){
        this.form = {
          reason: '',
          description: ''
        }
      }
    }
  }
</script>
