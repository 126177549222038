<template>
  <Loading v-if="isLoading"/>

  <div class="p-grid" v-show="!isLoading">

    <!-- Toolbar -->    
    <TitlePage 
        title="Tickets" 
        :reset="false" 
    />

    <!-- Table Result -->
    <div v-show="dataSet.length">
        <div class="col-12">
            <DataTable 
                :value="dataSet" 
                :rows="20"
                :rowClass="rowClass"
                :paginator="true" 
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                responsiveLayout="scroll" 
                :rowHover="true"
                v-model:filters="filters" 
                filterDisplay="row"
                :globalFilterFields="['id','name']"
            >
                <template #header>
                    <div class="flex justify-content-start">
                        
                        <span class="p-input-icon-left ">
                            <i class="fas fa-search" />
                            <InputText v-model="filters['global'].value" class="ml-2" placeholder="Filtrar Datos" />
                        </span>
                    </div>
                </template>

                <template #empty>{{ strings.es.dataTable.empty }}</template>
                <template #loading>{{ strings.es.dataTable.loading }}</template>

                <Column 
                    field="id" 
                    header="ID" 
                    :sortable="true" 
                    :style="{width:'50px'}"></Column>

                <Column 
                    header="Usuario" 
                    :sortable="true" 
                    v-if="this.$store.state.user.id == 1"
                    :style="{width:'100px'}">
                    <template #body="row">{{  row.data.user.username  }}</template>
                </Column>

                <Column 
                    field="reason.name" 
                    :sortable="true" 
                    header="Motivo"></Column>
                
                <Column 
                    field="state"
                    header="Estado" 
                    :sortable="true" 
                    :style="{width:'50px'}">
                    <template #body="row">{{  (row.data.state) ? 'Abierto' : 'Finalizado'  }}</template>
                </Column>
                
                <Column 
                    field="created_at"
                    header="Iniciado"
                    :sortable="true" >
                    <template #body="row">{{ (row.data.created_at != null) ? $moment.utc(row.data.created_at, 'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm") : '-' }}</template>
                </Column>

                <Column 
                    header="Finalizado"
                    :sortable="true" >
                    <template #body="row">{{ (row.data.updated_at != null && !row.data.state) ? $moment.utc(row.data.updated_at, 'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm") : '-' }}</template>
                </Column>

                <Column 
                    header="Ultimo Comentario"
                    :sortable="true" >
                    <template #body="row">{{ getUserComment(row.data.comments) }}</template>
                </Column>

                <Column :style="{width:'275px', 'text-align':'center' }">
                    <template #body="slotProps">
                        <span class="p-buttonset">
                          <Btn
                              v-bind:to="'/support/'+slotProps.data.id"
                              label='DETALLES'
                              iconPos='left'
                              icon='fas fa-edit'
                              classBtn='p-button-info p-button-text p-button-sm'
                          />
                        </span>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <NoResult v-show="!dataSet.length && !isLoading"/>

  </div>
</template>

<script>
  
  import apiService from '../../services/apiService.js'
  import { FilterMatchMode } from 'primevue/api';

  export default {
    data(){
      return { 
        isLoading: false,
        dataSet: [],
        reasons: [],
        filters: {
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            'id': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
            'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        },
      }
    },

    mounted(){
      this.index()
    },

    methods: {
      async index(){
          this.isLoading = true
          apiService.Assistance.get()
          .then((result) => {
              this.dataSet = result
              this.isLoading = false
          })
      },
      rowClass(data) {
          return !data.state ? 'bg-pink-50': null;
      },
      getUserComment(comments){
        if(comments && comments.length){
            const user_comment = comments[comments.length - 1].user;
            const user_session = this.$store.state.user
            
            if(user_comment.id == user_session.id){
                return "YO"; // user_session.last_name +', '+ user_session.first_name
            }

            return user_comment.last_name +', '+ user_comment.first_name
            
        }else{
            return "PENDIENTE";
        }
      }
    }
  }
</script>
