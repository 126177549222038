<template>
    <div class="col-12 card mt-3">

        <TabView lazy :activeIndex="activeIndex">
            <TabPanel>
                <template #header>
                    <i class="fas fa-history"></i>
                    <span class="pl-1">Consultas Realizadas</span>
                </template>
                <Assistance/>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="fas fa-comment"></i>
                    <span class="pl-1">Nueva Consulta</span>
                </template>
                <Create/>
            </TabPanel>
            
        </TabView>
    </div>
</template>

<script>

    import Assistance from '../Assistance/Assistance.vue'
    import Create from '../Assistance/Create.vue'

    export default {
        components:{
            Assistance,
            Create,
        },
        data(){
            return{
                activeIndex: 0,
                isLoading: false,
            }
        },
    }

</script>
